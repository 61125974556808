import React, { useState } from 'react';
import cx from 'classnames';
import styles from '../../VehicleCard.module.scss';
import { VehiclesResponseItem } from '../../../../../../types/vehiclesResponse.models';
import blankCar from '../../../../../../images/blank_car_PE.png'

interface CarJellyImageProps {
  offer: VehiclesResponseItem;
}

const CarJellyImage = ({
  offer
}: CarJellyImageProps) => {
  const [isPlaceholder, setIsPlaceholder] = useState(false);

  return (
    <div className={cx(styles.carJelly, isPlaceholder && styles.placeholder)}>
      <img
        src={offer.carJellyImage}
        alt={offer.carJellyImage}
        onError={event => {
          setIsPlaceholder(true);
          event.currentTarget.src = blankCar;
        }}
      />
    </div>
  );
};

export default CarJellyImage;
